import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getImageSize, getUuid } from '../utils/helpers';
import {
  generatedImageUrlAtom,
  gotGeneratedImageAtom,
  hasUpscaledImageAtom,
  imageProgressAtom,
  isProcessingAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  previousPromptAtom,
  promptAtom,
  queueInfoAtom,
  selectedCreativeElementAtom,
  selectedSizeAtom,
  selectedStylesAtom,
  tabSelectedAtom,
  uploadedImageAtom,
  uploadedImageNameAtom,
  userAtom,
} from '../utils/initState';
import { toastError } from '../utils/toastStyles';

export default function useWebsocket() {
  const [generatedImageUrl, setGeneratedImageUrl] = useAtom(generatedImageUrlAtom);
  // const [imageProgress, setImageProgress] = useAtom(imageProgressAtom);
  const setImageProgress = useSetAtom(imageProgressAtom);

  const user = useAtomValue(userAtom);
  const prompt = useAtomValue(promptAtom);
  const size = useAtomValue(selectedSizeAtom);
  const tabSelected = useAtomValue(tabSelectedAtom);
  const payloadWidth = useAtomValue(payloadWidthAtom);
  const payloadHeight = useAtomValue(payloadHeightAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const selectedStyles = useAtomValue(selectedStylesAtom);
  const selectedCreativeElement = useAtomValue(selectedCreativeElementAtom);

  const setQueueInfo = useSetAtom(queueInfoAtom);
  const setIsProcessing = useSetAtom(isProcessingAtom);
  const setUploadedImage = useSetAtom(uploadedImageAtom);
  const setPreviousPrompt = useSetAtom(previousPromptAtom);
  const setHasUpscaledImage = useSetAtom(hasUpscaledImageAtom);
  const setGotGeneratedImage = useSetAtom(gotGeneratedImageAtom);
  const setUploadedImageName = useSetAtom(uploadedImageNameAtom);

  function getAppendedStyles() {
    let appendedStyles = [];

    selectedStyles.map((style) => appendedStyles.push(`Style: ${style}`));

    return appendedStyles;
  }

  function getImage() {
    let tempProgress = 0;

    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);

    const { width, height } = getImageSize(size);

    const text2imgPayload = {
      task_id: getUuid(),
      width: width,
      height: height,
      prompt: prompt ? prompt : 'Busty woman in clothes',
      styles: getAppendedStyles(),
      // === Leave these defaults === //
      negative_prompt:
        'bad quality, extra limbs, disfigured, missing limbs, missing arm, extra arm, missing leg, extra leg, lowres, normal quality, ((young, child,)), blurry, blur, watermark, watermarks, ',
      sampler_name: 'DPM++ 2M SDE Karras',
      batch_size: 1,
      n_iter: 1,
      steps: 30,
      cfg_scale: 7,
      restore_faces: false,
      tiling: false,
      do_not_save_samples: false,
      do_not_save_grid: false,
      disable_extra_networks: false,
      enable_hr: false,
      hr_scale: 2,
      sampler_index: 'Euler',
      send_images: true,
      save_images: false,
      sd_model_checkpoint: 'WubsAnimeMix.safetensors',
      alwayson_scripts: {
        ADetailer: {
          args: [
            {
              ad_model: 'face_yolov8n.pt',
              ad_confidence: 0.8, // 0.3 default
              ad_mask_min_ratio: 0.025, // 0 default
            },
          ],
        },
      },
    };

    const img2imgPayload = {
      // === Reactive values === //
      task_id: getUuid(),
      init_images: generatedImageUrl ? [generatedImageUrl] : [uploadedImage],
      steps: 30,
      seed: -1,
      denoising_strength: selectedCreativeElement,
      width: payloadWidth,
      height: payloadHeight,
      prompt: prompt ? prompt : 'Busty woman in clothes',
      cfg_scale: 7,
      styles: getAppendedStyles(),
      sd_model_checkpoint: 'WubsAnimeMix.safetensors',
      negative_prompt:
        'bad quality, extra limbs, disfigured, missing limbs, missing arm, extra arm, missing leg, extra leg, lowres, normal quality, ((young, child,)), blurry, blur, watermark, watermarks, ',
      sampler_index: 'Euler',
    };

    const upscalePayload = {
      // === Reactive values === //
      task_id: getUuid(),
      init_images: generatedImageUrl ? [generatedImageUrl] : [uploadedImage],
      steps: 30,
      seed: -1,
      prompt: ' ',
      denoising_strength: 0.05,
      width: payloadWidth * 2,
      height: payloadHeight * 2,
      cfg_scale: 20,
      sampler_index: 'Euler',
      script_name: 'ultimate sd upscale',
      sd_model_checkpoint: 'WubsAnimeMix.safetensors',
      script_args: [
        null, // _ (not used)
        512, // tile_width
        512, // tile_height
        8, // mask_blur
        32, // padding
        64, // seams_fix_width
        0.35, // seams_fix_denoise
        32, // seams_fix_padding
        5, // upscaler_index
        true, // save_upscaled_image a.k.a Upscaled
        0, // redraw_mode
        false, // save_seams_fix_image a.k.a Seams fix
        8, // seams_fix_mask_blur
        0, // seams_fix_type
        0, // target_size_type
        1024, // custom_width
        1024, // custom_height
        2, // custom_scale
      ],
    };

    ws.onerror = (e) => {
      toastError.error(
        <span>
          A websocket error occured
          <a
            target="_blank"
            rel="noreferrer"
            className="toast-link"
            href="https://www.ecchiengine.com"
          >
            Click here
          </a>{' '}
          for more info
        </span>,
        {
          duration: 4000,
          style: toastError,
        }
      );
    };

    ws.onmessage = (e) => {
      const { dataType, data } = JSON.parse(e.data);

      let feature;
      let payload;

      switch (tabSelected) {
        case 1:
          feature = 'txt2img';
          payload = text2imgPayload;
          break;

        case 2:
          feature = 'img2img';
          payload = img2imgPayload;
          break;

        case 3:
          feature = 'img2img';
          payload = upscalePayload;
          break;

        default:
          feature = 'txt2img';
          payload = text2imgPayload;
          break;
      }

      if (dataType === 'connected') {
        setGeneratedImageUrl('');
        setIsProcessing(true);

        ws.send(
          JSON.stringify({
            feature,
            userId: user.uid,
            dataType: 'payload',
            data: { payload, clientId: data },
          })
        );
      }

      if (dataType === 'return image') {
        // Generate
        setQueueInfo('');
        setPreviousPrompt(prompt);
        setGeneratedImageUrl(data);
        setImageProgress(0);
        tempProgress = 0;
        setIsProcessing(false);
        document.title = 'Ecchi Engine';

        // Refine && Paint
        setGotGeneratedImage(true);
        setUploadedImage('');
        setUploadedImageName('');

        if (tabSelected === 3) {
          setHasUpscaledImage(true);
        } else {
          setHasUpscaledImage(false);
        }

        ws.close();
      }

      if (dataType === 'status update') {
        setQueueInfo(data);
        document.title = `${data} | Ecchi Engine ⏳`;
      }

      if (dataType === 'progress update') {
        const calcProgress = Math.floor(data * 100);

        if (tempProgress < calcProgress) {
          tempProgress = calcProgress;

          setImageProgress(calcProgress);

          document.title = `${calcProgress}% | Ecchi Engine 🎨`;
        } else if (calcProgress !== 0) {
          setImageProgress(100);
          document.title = '100% | Ecchi Engine 🎨';
        }

        if (data > 0) {
          setQueueInfo('');
        }
      }

      if (dataType === 'error') {
        // Generate
        setQueueInfo('');
        setGeneratedImageUrl('');
        setImageProgress(0);
        setIsProcessing(false);
        document.title = 'Ecchi Engine';

        // Refine && Paint
        setGotGeneratedImage(true);
        setUploadedImage('');
        setUploadedImageName('');

        ws.close();

        toastError.error(
          <span>
            Something went wrong.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              className="toast-link"
              href="https://www.ecchiengine.com"
            >
              Click here
            </a>{' '}
            for more info
          </span>,
          {
            duration: 4000,
            style: toastError,
          }
        );
      }
    };
  }

  return { getImage };
}
