export const toastError = {
  borderRadius: 0,
  background: '#090f13',
  color: '#ca3131',
  fontSize: '1rem',
};

export const toastSuccess = {
  borderRadius: 0,
  background: '#090f13',
  color: '#94db77',
  fontSize: '1rem',
};
