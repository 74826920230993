import {
  addDoc,
  and,
  collection,
  doc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, auth, db } from './firebase';

export async function getCheckoutUrl() {
  const userId = auth.currentUser?.uid;

  if (!userId) {
    throw new Error('User is not authenticated');
  }

  const checkoutSessionRef = collection(db, 'customers', userId, 'checkout_sessions');

  const docRef = await addDoc(checkoutSessionRef, {
    price: process.env.REACT_APP_STRIPE_PRICE_LINK,
    allow_promotion_codes: true,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      const { error, url } = snapshot.data();

      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }

      if (url) {
        unsubscribe();
        resolve(url);
      }
    });
  });
}

export async function getPortalUrl() {
  const user = auth.currentUser;

  let dataWithUrl;

  try {
    const cloudFunctions = getFunctions(app, 'us-east4');

    const functionRef = httpsCallable(
      cloudFunctions,
      'ext-firestore-stripe-payments-createPortalLink'
    );

    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    dataWithUrl = data;
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error('No url returned'));
    }
  });
}

export async function getMembershipStatus() {
  const userId = auth.currentUser?.uid;

  if (!userId) {
    throw new Error('User not logged in');
  }

  const subscriptionsRef = collection(db, 'customers', userId, 'subscriptions');

  const productRef = doc(db, `products/${process.env.REACT_APP_STRIPE_PRODUCT_ID}`);

  const q = query(
    subscriptionsRef,
    and(where('status', 'in', ['trialing', 'active']), where('product', '==', productRef))
  );

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (snapshot.docs.length === 0) {
          resolve(false);
        } else {
          resolve(true);
        }

        unsubscribe();
      },
      reject
    );
  });
}
