import { Provider as JotaiProvider } from "jotai";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AppLoader from "./AppLoader";
import "./styles/index.scss";

const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <JotaiProvider>
      <AppLoader />
    </JotaiProvider>
  </StrictMode>,
);
