import { useSetAtom } from 'jotai';
import productIcon from '../../images/SubscribeIcon.webp';
import { getCheckoutUrl } from '../../stripePayment';
import { isLoadingAtom } from '../../utils/initState';
import Button from '../Button';
import { ProductIcon } from '../Icons';

export default function ProductCard({ label, price, actionLabel }) {
  const setIsLoading = useSetAtom(isLoadingAtom);

  async function buyMembership() {
    setIsLoading(true);
    const checkoutUrl = await getCheckoutUrl();

    window.location.href = checkoutUrl;
  }

  return (
    <div className="product-card-wrapper">
      <h1>Get started with Ecchi Engine</h1>

      <div className="product-card">
        <img src={productIcon} alt="" />

        <br />

        <h1>{label}</h1>

        <br />

        <p className="price">{price}</p>

        <br />

        <h2>{actionLabel}</h2>

        <br />

        <ul>
          <li>Cancel anytime</li>
          <li>Latest models</li>
          <li>Unlimited image generation</li>
          <li>Mobile friendly</li>
          <li>Runs in the browser</li>
        </ul>

        <br />

        <Button
          label="Buy now"
          icon={<ProductIcon size={24} />}
          styles="btn-block btn-green"
          onClick={buyMembership}
        />

        <br />
      </div>
    </div>
  );
}
