import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import Loader from './components/Loader';
import { auth, db } from './firebase';
import BannedPage from './pages/BannedPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import { getMembershipStatus } from './stripePayment';
import { appLoadingAtom, isBannedAtom, isMemberAtom, userAtom } from './utils/initState';

export default function AppLoader() {
  const [user, setUser] = useAtom(userAtom);
  const [isBanned, setIsBanned] = useAtom(isBannedAtom);
  const [appLoading, setAppLoading] = useAtom(appLoadingAtom);

  const setIsMember = useSetAtom(isMemberAtom);

  //=== Check if user is authenticated ===//
  useEffect(() => {
    setAppLoading(true);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setAppLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [setAppLoading, setUser]);

  //=== Check if user is a paid subscriber ===//
  useEffect(() => {
    setAppLoading(true);

    async function checkMembership() {
      if (!user) {
        setIsMember(false);
      }

      const status = user ? await getMembershipStatus() : false;

      setIsMember(status);
      setAppLoading(false);
    }

    checkMembership();
  }, [user, setIsMember, setAppLoading]);

  //=== Check if user is banned ===//
  useEffect(() => {
    setAppLoading(true);

    async function getBannedStatus() {
      const userId = auth.currentUser?.uid;

      if (!userId) {
        setIsBanned(false);
        setAppLoading(false);
      } else {
        const bannedUserDocRef = doc(db, 'bannedUsers', userId);

        const docSnap = await getDoc(bannedUserDocRef);

        if (docSnap.exists()) {
          setIsBanned(true);
          setAppLoading(false);
        } else {
          setIsBanned(false);
          setAppLoading(false);
        }
      }
      setAppLoading(false);
    }

    getBannedStatus();
  }, [setAppLoading, setIsBanned, user]);

  if (appLoading) {
    return <Loader />;
  }

  if (isBanned) {
    return <BannedPage />;
  }

  if (user) {
    return <HomePage />;
  } else {
    return <LoginPage />;
  }
}
