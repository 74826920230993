import model3d from '../images/3d-model.webp';
import flames from '../images/Flames.webp';
import ghibs from '../images/Ghibs.webp';
import noir from '../images/Noir.webp';
import zombified from '../images/Zombified.webp';
import analogFilm from '../images/analogfilm.webp';
import anime from '../images/anime.webp';
import biomech from '../images/biomechanical.webp';
import comicBook from '../images/comicbook.webp';
import conceptArt from '../images/concept.webp';
import enhance from '../images/enhance.webp';
import fantasyArt from '../images/fantasyart.webp';
import kawaii from '../images/kawaii.webp';
import lineArt from '../images/lineart.webp';
import lovecraftian from '../images/lovecraftian.webp';
import lowpoly from '../images/lowpoly.webp';
import neonpunk from '../images/neonpunk.webp';
import oilPainting from '../images/oilpainting.webp';
import origami from '../images/origami.webp';
import toys from '../images/toys.webp';
import furries from '../images/Furries.webp'
import monsterGirls from '../images/MonsterGirls.webp'

// Sorts the styles list alphabetically
function sortName(a, b) {
  return a.label.localeCompare(b.label);
}

export const styles = [
  {
    id: 'Enhance',
    label: 'Enhance',
    image: enhance,
    override: {},
  },
  {
    id: '90s anime',
    label: '90s anime',
    image: anime,
    override: {},
  },
  {
    id: 'Concept art',
    label: 'Concept art',
    image: conceptArt,
    override: {},
  },
  {
    id: 'Comic book',
    label: 'Comic book',
    image: comicBook,
    override: {},
  },
  {
    id: 'Fantasy art',
    label: 'Fantasy art',
    image: fantasyArt,
    override: {},
  },
  {
    id: 'Manga',
    label: 'Manga',
    image: analogFilm,
    override: {},
  },
  {
    id: 'Neon punk',
    label: 'Neon punk',
    image: neonpunk,
    override: {},
  },
  {
    id: 'Lowpoly',
    label: 'Lowpoly',
    image: lowpoly,
    override: {},
  },
  {
    id: 'Origami',
    label: 'Origami',
    image: origami,
    override: {},
  },
  {
    id: 'Line art',
    label: 'Line art',
    image: lineArt,
    override: {},
  },
  {
    id: 'Toys',
    label: 'Toys',
    image: toys,
    override: {},
  },
  {
    id: '3D Model',
    label: '3D Model',
    image: model3d,
    override: {},
  },
  {
    id: 'Zombified',
    label: 'Zombified',
    image: zombified,
    override: {},
  },
  {
    id: 'Noir',
    label: 'Noir',
    image: noir,
    override: {},
  },
  {
    id: 'Lovecraftian',
    label: 'Lovecraftian',
    image: lovecraftian,
    override: {},
  },
  {
    id: 'Flames',
    label: 'Flames',
    image: flames,
    override: {},
  },
  {
    id: 'Oil Painting',
    label: 'Oil Painting',
    image: oilPainting,
    override: {},
  },
  {
    id: 'Biomech',
    label: 'Biomech',
    image: biomech,
    override: {},
  },
  {
    id: 'Kawaii',
    label: 'Kawaii',
    image: kawaii,
    override: {},
  },
  {
    id: 'Ghibs',
    label: 'Ghibs',
    image: ghibs,
    override: {},
  },
  {
    id: 'Monster girls',
    label: 'Monster girls',
    image: monsterGirls,
    override: {},
  },
  {
    id: 'Furries',
    label: 'Furries',
    image: furries,
    override: {},
  },
].sort(sortName);
