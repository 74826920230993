import { useAtom } from 'jotai';
import { tabSelectedAtom } from '../../utils/initState';
import { NewFeatureBadge } from '../NewFeatureBadge';

export default function TabBar() {
  const [tabSelected, setTabSelected] = useAtom(tabSelectedAtom);

  const tabArray = [
    { id: 1, label: 'Generate', new: false },
    { id: 2, label: 'Refine', new: false },
    { id: 3, label: 'Upscale', new: false },
  ];

  function getStyle(tabId) {
    return `tab-bar-item ${tabSelected === tabId && 'tab-bar-item-selected'}`;
  }

  function handleOnEnter(e, tabId) {
    if (e.key === 'Enter') {
      setTabSelected(tabId);
    }
  }

  return (
    <div className="tab-view">
      <div className="tab-bar">
        {tabArray.map((tab) => {
          return (
            <div
              role="button"
              key={tab.id}
              tabIndex={0}
              onClick={() => {
                setTabSelected(tab.id);
              }}
              onKeyDown={(e) => handleOnEnter(e, tab.id)}
              className={getStyle(tab.id)}
            >
              {tab.label}
              {tab.new && <NewFeatureBadge />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
