import Brand from '../components/Brand';
import GoogleButton from '../components/GoogleButton';
// import { LogoIcon } from '../components/Icons';
import logoImage from '../images/Logo.webp';

export default function LoginPage() {
  return (
    <div className="login-page">
      <div className="login-page-wrapper">
        <div className="login-page-logo">
          {/* <LogoIcon size={200} /> */}

          <img className="logo-login" src={logoImage} alt="ecchi engine logo" />
        </div>

        <div className="sign-in-text">
          <h3>Welcome to</h3>
          <Brand />
        </div>

        <GoogleButton />
      </div>
    </div>
  );
}
