import { signOut } from 'firebase/auth';
import { useAtomValue, useSetAtom } from 'jotai';
import { auth } from '../../firebase';
// import useTheme from "../../hooks/useTheme";
import { getPortalUrl } from '../../stripePayment';
import { isLoadingAtom, isMemberAtom, menuOpenAtom } from '../../utils/initState';
import Button from '../Button';
import { RightArrow, SignOutIcon } from '../Icons';

export default function DropDown() {
  // const { theme, toggleTheme } = useTheme();
  const avatar = auth.currentUser?.photoURL;

  const setIsLoading = useSetAtom(isLoadingAtom);

  const menuOpen = useAtomValue(menuOpenAtom);
  const isMember = useAtomValue(isMemberAtom);

  function handleSignOut() {
    signOut(auth);

    window.location.reload();
  }

  async function manageMembership() {
    setIsLoading(true);
    const portalUrl = await getPortalUrl();

    window.location.href = portalUrl;
  }

  return (
    <div className="modal-menu-wrapper">
      <div className={`modal-menu-backdrop ${menuOpen ? 'active' : 'inactive'}`}>
        <div className={`modal-menu user-menu ${menuOpen ? 'active' : 'inactive'}`}>
          <div className="modal-menu-top-container">
            <img
              src={avatar}
              width={150}
              height={150}
              alt="user avatar"
              className="modal-avatar"
            />

            <h3>{auth.currentUser?.displayName}</h3>

            <p>{auth.currentUser?.email}</p>

            <br />
          </div>

          <hr className="line-break" />

          <ul>
            <li className="modal-item">
              <h3 className="text-gray" style={{ marginRight: '0.25rem' }}>
                Subscription:{' '}
              </h3>
              {isMember ? (
                <span className="text-green">Active</span>
              ) : (
                <span className="text-red">Inactive</span>
              )}
            </li>

            <li className="modal-item">
              <span className="text-gray">Need help?</span>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://www.ecchiengine.com/post/creating-your-first-ai-generated-anime-artwork-step-by-step'
                }
              >
                Read this tutorial
              </a>
            </li>
          </ul>

          <div className="button-row-menu">
            {isMember && (
              <Button
                label="Manage subscription"
                styles="btn-block btn-small btn-dark hover-green"
                onClick={manageMembership}
                icon={<RightArrow size={20} />}
              />
            )}

            <Button
              label="Sign out"
              styles="btn-block btn-small btn-dark text-red"
              onClick={handleSignOut}
              icon={<SignOutIcon size={20} />}
            />
          </div>

          <hr className="line-break" />

          <ul>
            <li className="modal-item">
              <span className="text-gray">Need support? You can reach us here:</span>
            </li>

            <li className="modal-item">
              <a href="mailto:contact@ecchiengine.com">contact@ecchiengine.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

// <li className="modal-item">
//   <div className="text-gray"> Toggle theme: </div>
//   {theme === "light" ? (
//     <div
//       onClick={toggleTheme}
//       className="text-green modal-toggle"
//     >
//       <ToggleOnIcon size={20} />
//     </div>
//   ) : (
//     <div onClick={toggleTheme} className="text-gray modal-toggle">
//       <ToggleOffIcon size={20} />
//     </div>
//   )}
//   <NewFeatureBadge />
// </li>
