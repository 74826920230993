import { useAtomValue } from 'jotai';
import { tabSelectedAtom } from '../../utils/initState';
import TutorialItem from '../TutorialItem';

export default function Tutorial() {
  const tabSelected = useAtomValue(tabSelectedAtom);

  return (
    <>
      {tabSelected === 1 && (
        <TutorialItem
          title="Generate:"
          contentFirst="Generate image from simple text prompts."
          contentSecond="Choose none, one, or multiple styles for customization."
          link="https://www.ecchiengine.com/post/creating-your-first-ai-generated-anime-artwork-step-by-step"
        />
      )}

      {tabSelected === 2 && (
        <TutorialItem
          title="Refine:"
          contentFirst="Use text prompts to direct the image's refinement."
          contentSecond="Add styles for further personalization."
          link="https://www.ecchiengine.com/post/refining-your-images-with-ai"
        />
      )}

      {tabSelected === 3 && (
        <TutorialItem
          title="Upscale:"
          contentFirst="Upscale will double your image's resolution."
          contentSecond="Maximum upload size is 1536 x 1536"
          link="https://www.ecchiengine.com/post/upscale-your-image"
        />
      )}
    </>
  );
}
