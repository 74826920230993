import { useAtom, useAtomValue } from 'jotai';
import { forwardRef, useEffect } from 'react';
import placeholder2 from '../../images/PlaceholderImage1.webp';
import placeholder from '../../images/PlaceholderImage3.webp';

import {
  generatedImageUrlAtom,
  imageAtom,
  isProcessingAtom,
  promptAtom,
  uploadedImageAtom,
} from '../../utils/initState';

const ImageLoader = forwardRef(function ImageLoader(props, ref) {
  const imageRef = ref;

  const [image, setImage] = useAtom(imageAtom);

  const prompt = useAtomValue(promptAtom);

  const isProcessing = useAtomValue(isProcessingAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);

  useEffect(() => {
    if (generatedImageUrl) {
      setImage(generatedImageUrl);
    } else if (!isProcessing && uploadedImage) {
      setImage(uploadedImage);
    } else if (isProcessing) {
      setImage(placeholder2);
    } else {
      setImage(placeholder);
    }
  }, [isProcessing, setImage, generatedImageUrl, uploadedImage]);

  function imageLoader() {
    props.reportWindowSize();
  }

  return (
    <img src={image} ref={imageRef} onLoad={imageLoader} className="image" alt={prompt} />
  );
});

export default ImageLoader;
