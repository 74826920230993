import { useAtom } from 'jotai';
import { selectedCreativeElementAtom } from '../../utils/initState';

export default function CreativeSelector() {
  return (
    <div className="size-selector-wrapper">
      <CreativeElement weight={0.3} label="Strict" />
      <CreativeElement weight={0.6} label="Balanced" />
      <CreativeElement weight={0.85} label="Creative" />
    </div>
  );
}

function CreativeElement({ weight, label }) {
  const [selectedCreativeElement, setSelectedCreativeElement] = useAtom(
    selectedCreativeElementAtom
  );

  const handleCreativeElementSelect = () => {
    setSelectedCreativeElement(weight);
  };

  return (
    <div
      onClick={handleCreativeElementSelect}
      className={`size-selector-section ${
        selectedCreativeElement === weight && 'size-selector-section-active'
      }`}
    >
      {label ?? weight}
    </div>
  );
}
