export default function HelpText() {
  return (
    <div className="modal-help-link-text">
      <h3>
        Having issues? <a href="mailto:contact@ecchiengine.com">Contact us</a>
        <p>contact@ecchiengine.com</p>
      </h3>
    </div>
  );
}
