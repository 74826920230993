import { useAtom } from 'jotai';
import { promptAtom } from '../../utils/initState';

export default function PromptInput() {
  const [prompt, setPrompt] = useAtom(promptAtom);

  return (
    <div className="prompt-input-wrapper">
      <input
        required
        tabIndex={0}
        type="text"
        value={prompt}
        maxLength={320}
        className="prompt-input"
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Enter a prompt here (example: Busty woman is street clothes)"
      />
    </div>
  );
}
