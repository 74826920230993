import { useAtom } from 'jotai';
import { auth } from '../../firebase';
import { menuOpenAtom } from '../../utils/initState';

export default function MenuIcon() {
  const [menuOpen, setMenuOpen] = useAtom(menuOpenAtom);

  const size = 28;
  const red = '#ff5252';
  const gray = '#2f354f';

  const avatar = auth.currentUser?.photoURL;

  function handleOnEnter(e) {
    if (e.key === 'Enter') {
      setMenuOpen(!menuOpen);
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className="settings-icon"
      onKeyDown={handleOnEnter}
      onClick={() => setMenuOpen(!menuOpen)}
    >
      {menuOpen ? (
        <svg
          color={red}
          width={size}
          height={size}
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      ) : avatar ? (
        <img
          src={avatar}
          width={size}
          height={size}
          className="avatar"
          alt="user avatar"
        />
      ) : (
        <svg
          color={gray}
          width={size}
          height={size}
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path
            fillRule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
          />
        </svg>
      )}
    </div>
  );
}
