import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import useWebsocket from '../../hooks/useWebsocket';
import { getImageSize } from '../../utils/helpers';
import {
  generatedImageUrlAtom,
  gotGeneratedImageAtom,
  isLoadingAtom,
  isProcessingAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  scaleAtom,
  selectedSizeAtom,
  uploadedImageAtom,
  uploadedImageHeightAtom,
  uploadedImageWidthAtom,
} from '../../utils/initState';
import { toastError } from '../../utils/toastStyles';
import Button from '../Button';
import CreativeSelector from '../CreativeSelector';
import DownloadButton from '../DownloadButton';
import { ImageIcon } from '../Icons';
import Loader from '../Loader';
import PromptInput from '../PromptInput';
import StyleSelector from '../StyleSelector';
import UploadImage from '../UploadImage';

export default function Image2Image() {
  const { getImage } = useWebsocket();

  const [scale, setScale] = useAtom(scaleAtom);

  const [payloadWidth, setPayloadWidth] = useAtom(payloadWidthAtom);
  const [payloadHeight, setPayloadHeight] = useAtom(payloadHeightAtom);

  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);
  const gotGeneratedImage = useAtomValue(gotGeneratedImageAtom);

  const size = useAtomValue(selectedSizeAtom);
  const isLoading = useAtomValue(isLoadingAtom);
  const isProcessing = useAtomValue(isProcessingAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const uploadedImageWidth = useAtomValue(uploadedImageWidthAtom);
  const uploadedImageHeight = useAtomValue(uploadedImageHeightAtom);

  const minSize = 512;
  const maxSize = 1024;

  function handleClick() {
    if (uploadedImage || generatedImageUrl) {
      getImage();
    } else {
      toast.error('You must upload or generate an image first', {
        duration: 4000,
        style: toastError,
      });
    }
  }

  useEffect(() => {
    // This makes sure smaller images are upscaled to fit the size constraints of SD
    if (generatedImageUrl) {
      if (payloadWidth > maxSize || payloadHeight > maxSize) {
        setScale(maxSize / Math.max(payloadWidth, payloadHeight));
      }

      if (payloadWidth < minSize || payloadHeight < minSize) {
        setScale(minSize / Math.min(payloadWidth, payloadHeight));
      }
    }

    if (uploadedImage) {
      if (uploadedImageWidth > maxSize || uploadedImageHeight > maxSize) {
        setScale(maxSize / Math.max(uploadedImageWidth, uploadedImageHeight));
      }

      if (uploadedImageWidth < minSize || uploadedImageHeight < minSize) {
        setScale(minSize / Math.min(uploadedImageWidth, uploadedImageHeight));
      }
    }
  }, [
    setScale,
    uploadedImage,
    uploadedImageHeight,
    uploadedImageWidth,
    payloadWidth,
    payloadHeight,
    generatedImageUrl,
  ]);

  useEffect(() => {
    const { width, height } = getImageSize(size);

    if (gotGeneratedImage) {
      const img = new Image();

      img.src = generatedImageUrl;

      img.onload = () => {
        setPayloadWidth(img.width);
        setPayloadHeight(img.height);
      };
    } else if (uploadedImage) {
      setPayloadWidth(generatedImageUrl ? width : uploadedImageWidth);
      setPayloadHeight(generatedImageUrl ? height : uploadedImageHeight);
    }
  }, [
    size,
    scale,
    uploadedImage,
    generatedImageUrl,
    gotGeneratedImage,
    uploadedImageWidth,
    uploadedImageHeight,
    setPayloadHeight,
    setPayloadWidth,
  ]);

  if (isLoading) {
    return <Loader label="Loading, this may take a few seconds..." />;
  }

  if (isProcessing) {
    return <></>;
  }

  return (
    <>
      <CreativeSelector />

      <PromptInput />

      <StyleSelector />

      <div className="button-row">
        <UploadImage />

        <Button
          tabIndex={0}
          label="Generate"
          onClick={handleClick}
          icon={<ImageIcon size={20} />}
          styles="btn-block btn-green"
        />

        {generatedImageUrl && <DownloadButton />}
      </div>
    </>
  );
}
