import { useSetAtom } from 'jotai';
import Button from '../components/Button';
import { LogoIcon } from '../components/Icons';
import { getPortalUrl } from '../stripePayment';
import { isLoadingAtom } from '../utils/initState';

export default function BannedPage() {
  const setIsLoading = useSetAtom(isLoadingAtom);

  async function manageMembership() {
    setIsLoading(true);
    const portalUrl = await getPortalUrl();

    window.location.href = portalUrl;
  }

  return (
    <div className="login-page">
      <div className="login-page-wrapper">
        <div className="login-page-logo">
          <LogoIcon size={200} />
        </div>

        <div className="sign-in-text">
          <h3>
            You've been banned for a{' '}
            <a target="_blank" rel="noreferrer" href="https://www.ecchiengine.com">
              terms of service{' '}
            </a>
            violation
          </h3>
        </div>

        <Button
          styles="btn-block btn-red"
          label="Manage membership"
          onClick={manageMembership}
        />
      </div>
    </div>
  );
}
