import { useAtom } from 'jotai';
import toast from 'react-hot-toast';
import {
  isStyleSelecterOpenAtom,
  selectedStylesAtom,
  styleSelecterHighlightedIndexAtom,
} from '../../utils/initState';
import { styles } from '../../utils/modelStyles';
import { toastSuccess } from '../../utils/toastStyles';
import Button from '../Button';

export default function StyleSelector() {
  const [selectedStyles, setSelectedStyles] = useAtom(selectedStylesAtom);

  const [styleSelecterHighlightedIndex, setStyleSelecterHighlightedIndex] = useAtom(
    styleSelecterHighlightedIndexAtom
  );

  const [isStyleSelecterOpen, setIsStyleSelecterOpen] = useAtom(isStyleSelecterOpenAtom);

  function toggle() {
    setIsStyleSelecterOpen(!isStyleSelecterOpen);
  }

  function clearStyles() {
    setSelectedStyles([]);

    toast.success('Styles cleared', {
      duration: 1000,
      style: toastSuccess,
    });
  }

  function isStyleSelected(style) {
    return selectedStyles.includes(style.id);
  }

  function selectStyle(style) {
    if (selectedStyles.includes(style)) {
      setSelectedStyles(selectedStyles.filter((v) => v !== style));
    } else {
      setSelectedStyles([...selectedStyles, style]);
    }
  }

  function getLabel() {
    if (!selectedStyles.length) {
      return 'Select 1 or more styles';
    }
    if (selectedStyles.length === 1) {
      return `${selectedStyles.length} style selected`;
    }
    if (selectedStyles.length > 1) {
      return `${selectedStyles.length} styles selected`;
    }
  }

  function handleOnEnter(e) {
    if (e.key === 'Enter') {
      setIsStyleSelecterOpen(!isStyleSelecterOpen);
    }
  }

  function handleSelectStyleOnEnter(e, styleId) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      selectStyle(styleId);
    }
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={toggle}
        onKeyDown={handleOnEnter}
        className="style-selector"
      >
        <span className="style-selector-value">
          {!selectedStyles.length ? (
            <span className="style-selector-choose-style">Pick some styles here...</span>
          ) : (
            selectedStyles.map((val, idx) => <Tag key={idx} val={val} idx={idx} />)
          )}
        </span>

        <div className="style-selector-caret" />

        <div className="style-selector-divider" />

        <button
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation();
            clearStyles();
          }}
          className="style-selector-clear-btn"
        >
          &times;
        </button>

        <div
          className={`modal-menu-backdrop ${isStyleSelecterOpen ? 'active' : 'inactive'}`}
        >
          <div className={`modal-menu ${isStyleSelecterOpen ? 'active' : 'inactive'}`}>
            <div className="new-style-selector-wrapper">
              <div className="new-style-selector-header">{getLabel()}</div>

              <div className="new-style-selector">
                {styles.map((style, idx) => {
                  return (
                    <div
                      key={idx}
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectStyle(style.id);
                      }}
                      onKeyDown={(e) => handleSelectStyleOnEnter(e, style.id)}
                      onMouseEnter={() => setStyleSelecterHighlightedIndex(idx)}
                      className={`new-style-selector-item ${
                        isStyleSelected(style) && 'new-style-selector-item-selected'
                      } ${
                        idx === styleSelecterHighlightedIndex &&
                        'new-style-selector-item-highlighted'
                      }`}
                    >
                      <img src={style.image} alt={style.label} />
                      <p>{style.label}</p>
                    </div>
                  );
                })}
              </div>

              <div className="new-style-selector-footer">
                <Button
                  tabIndex={0}
                  styles="button btn-block btn-small btn-green"
                  onClick={toggle}
                  label="Save"
                />

                <Button
                  tabIndex={0}
                  styles="button btn-block btn-small btn-clear"
                  label="&times; Clear"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearStyles();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Tag({ val, idx }) {
  const [selectedStyles, setSelectedStyles] = useAtom(selectedStylesAtom);

  const selectStyle = (style) => {
    if (selectedStyles.includes(style)) {
      setSelectedStyles(selectedStyles.filter((v) => v !== style));
    } else {
      setSelectedStyles([...selectedStyles, style]);
    }
  };

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        selectStyle(val);
      }}
      className="style-selector-option-badge"
    >
      {val}
      <span className="style-selector-clear-btn">&times;</span>
    </button>
  );
}
