import { useAtom } from 'jotai';
import { selectedSizeAtom } from '../../utils/initState';

export default function SizeElement({ size, label, icon }) {
  const [selectedSize, setSelectedSize] = useAtom(selectedSizeAtom);

  function handleSizeSelect() {
    setSelectedSize(size);
  }

  function handleOnEnter(e) {
    if (e.key === 'Enter') {
      setSelectedSize(size);
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={handleOnEnter}
      onClick={handleSizeSelect}
      className={`size-selector-section ${
        selectedSize === size && 'size-selector-section-active'
      }`}
    >
      <div className="size-selector-section-icon">{icon}</div>

      {label ?? size}
    </div>
  );
}
